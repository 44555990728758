import {Action} from 'api/entities';

export const ACL_ROLES = {
  ROOT: 'ROOT'
};

export const ACL_PERMS = {
  /* General */

  // Groups
  CREATE_REMOVE_GROUPS: Action._2,
  EDIT_GROUP: Action._3,
  EDIT_GROUP_TAGS: Action._122,
  // Dashboards
  CRUD_DASHBOARDS: Action._111,
  CRUD_CATEGORIES: Action._112,
  CRUD_CREATION_SETS: Action._113,

  /* Devices */

  // Devices
  CREATE_DELETE_DEVICES: Action._67,
  EDIT_DEVICES: Action._87,
  SHOW_DEVICES: Action._66,
  // Alerting
  EDIT_ALARMS: Action._85,
  SHOW_ALARMS: Action._84,
  // Recordings
  EDIT_RECORDINGS: Action._83,
  SHOW_RECORDINGS: Action._82,
  // Data Points and Filters
  // Data Exchange
  EDIT_DATA_IMPORT: Action._78,
  SHOW_DATA_IMPORT: Action._77,
  // Data Points
  CREATE_DELETE_DATA_POINTS: Action._103,
  EDIT_DATA_POINTS: Action._72,
  SHOW_DATA_POINTS: Action._88,
  // Data Point Tags
  EDIT_DATA_POINT_TAGS: Action._118,
  // Measurements
  EDIT_MEASUREMENTS: Action._101,
  EDIT_METERS_MEASUREMENTS: Action._124,
  // IoT Profiles
  EDIT_IOT_PROFILE: Action._115,
  // Objects on the Network
  EDIT_NETWORK_OBJECTS: Action._110,
  SHOW_NETWORK_OBJECTS: Action._109,
  // Addressing Systems
  MANAGE_ADDRESSING_SYSTEM: Action._120,

  /* Documents */

  // Documents
  SHOW_DOCUMENTS: Action._21,
  UPLOAD_DOCUMENTS: Action._22,
  // Report Jobs
  EDIT_REPORT_JOBS: Action._105,
  SHOW_REPORT_JOBS: Action._104,

  /* Settings */

  // General
  EDIT_CLIENT: Action._48,
  // Branding
  EDIT_BRAND: Action._114,
  // Log
  CREATE_LOG_ENTRY: Action._90,
  SHOW_LOG_DATA_POINT_VALUE_SET: Action._91,
  SHOW_LOG_GENERAL: Action._92,
  SHOW_LOGINS: Action._93,

  /* Users */

  // Addresses
  CREATE_DELETE_ADDRESSES: Action._64,
  EDIT_ADDRESSES: Action._65,
  SHOW_ADDRESSES: Action._63,
  // Alarm Chains
  ADD_CHANGE_DELETE_ATTENDANCES: Action._62,
  CHANGE_ALARM_CHAIN_USER_ORDER: Action._60,
  CREATE_EDIT_DELETE_ALARM_CHAINS: Action._59,
  SHOW_ALARM_CHAINS: Action._58,
  SHOW_ATTENDANCES: Action._61,
  // User Groups
  CREATE_DELETE_USER_GROUPS: Action._50,
  EDIT_USER_GROUPS: Action._51,
  SHOW_USER_GROUPS: Action._49,
  // User Rights
  ASSIGN_GROUP_RIGHTS: Action._53,
  SHOW_GROUP_RIGHTS: Action._52,
  // User Roles
  ASSIGN_ACTION_RIGHTS: Action._57,
  CREATE_DELETE_USER_ROLES: Action._55,
  SHOW_ACTION_RIGHTS: Action._56,
  SHOW_USER_ROLES: Action._54,
  // Users
  CRUD_USERS: Action._46,
  SHOW_USERS: Action._45,

  /* Super Administrator */
  SUPER_ADMIN_SHOW_ALL_DEVICES: Action._127,
  SUPER_ADMIN_SHOW_BILLING: Action._126,
  SUPER_ADMIN_SHOW_SHOP: Action._125,

  /* Widgets */

  // Charts
  EDIT_CHARTS: Action._27,

  // Custom Data Points
  CREATE_DELETE_CUSTOM_DATA_POINTS: Action._30,
  SHOW_CUSTOM_DATA_POINTS: Action._29,
  EDIT_CUSTOM_DATA_POINTS: Action._31,

  // Meter Network
  SHOW_EXPORT_NETWORK: Action._33,
  EDIT_NETWORK: Action._34,
  UPLOAD_CALCULATE_EXPORT_HEATING_COSTS: Action._36,

  // Tenancies
  TENANCIES_CRUD: Action._38,
  SHOW_TENANCIES: Action._37,

  // Ancillary Cost Settlement
  // Invoices
  ADD_REMOVE_INVOICES: Action._41,
  SHOW_INVOICES_FOR_COST_CENTERS: Action._40,
  // Invoices summary
  SHOW_COST_CENTER_SUMMARY: Action._79,
  // Tenant Payments
  ADD_REMOVE_TENANT_PAYMENTS: Action._43,
  SHOW_TENANT_PAYMENTS: Action._42,
  // Settlement Preview
  SHOW_EXPORT_BILLING_OVERVIEW: Action._44,

  // Tenant Assistance
  // Bulletin
  CRUD_BULLETIN_MESSAGES: Action._18,
  // Real Estate Management
  EDIT_REAL_ESTATE_PROPERTY_INFORMATION: Action._12,
  MANAGE_TENANT_ASSISTANCE: Action._121,
  MANAGE_ENERGY_PROVIDER_COEFFICIENTS: Action._119,

  // Schematic
  EDIT_MASTER_PAGES: Action._108,
  EDIT_SCHEMATICS: Action._25,
  WRITE_DATAPOINT_VALUES: Action._94,
  // Shape Editor
  EDIT_SHAPES: Action._98,
  SHOW_SHAPE_DESIGNER: Action._97,

  // Tickets
  ACKNOWLEDGE_CLOSE_TICKETS: Action._6,
  CLOSE_TICKETS_DIRECTLY: Action._7,
  DELETE_TICKETS: Action._8,
  EDIT_TEXT_BLOCKS: Action._106,
  EDIT_TICKET_TAGS: Action._107,
  // Building Tickets (a.k.a. User Reports)
  CREATE_EDIT_BUILDING_TICKETS: Action._102,

  // Service Level Agreement
  EDIT_SLA: Action._116,

  // Table Report
  EDIT_TABLE_REPORT: Action._117
};
